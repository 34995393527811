<template>
  <nav id="navbar">
    <v-app-bar
      dense
      app
      flat
      elevate-on-scroll
      color="white"
      :class="
        isLandingPage
          ? this.$vuetify.breakpoint.mdAndUp
            ? 'pt-2 pb-15 d-flex justify-center'
            : 'pt-1 d-flex justify-center'
          : 'd-flex justify-center'
      "
    >
      <span style="width: 80vw" class="d-flex align-center">
        <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->
        <v-toolbar-title class="ml-sm-2 ml-md-12">
          <v-btn
            :to="auth ? '/home' : '/'"
            class="text-capitalize rounded-xl"
            depressed
            text
          >
            <span class="d-flex align-center">
              <v-icon size="18">mdi-home-variant</v-icon>
              <span>Plus</span>
              <span class="deep-purple--text">Minus</span>
            </span>
          </v-btn>
        </v-toolbar-title>
        <v-spacer></v-spacer>

        <!-- Navigation buttons top right -->
        <NavButtons
          :isAuth="auth"
          :options="auth ? authOptions : guestOptions"
          @logout="logout"
        />
      </span>
    </v-app-bar>

    <!-- Side Drawer -->
  </nav>
</template>
  
<script>
import NavButtons from "@/components/NavBar/NavButtons.vue";

export default {
  name: "Navbar",
  components: { NavButtons },
  computed: {
    isLandingPage() {
      return this.$route.name == "Landing";
    },
    auth: {
      get() {
        return this.$store.getters.auth;
      },
      set(newAuth) {
        return newAuth;
      },
    },
  },
  data: () => ({
    guestOptions: [
      { to: "/login", icon: "mdi-login-variant", text: "Login" },
      { to: "/register", icon: "mdi-account-plus-outline", text: "Register" },
    ],
    authOptions: [{ to: "/home", icon: "mdi-home-outline", text: "Home" }],
  }),
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "Landing" });
      });
    },
  },
};
</script>