var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"courses"}},[_c('v-container',[_c('v-row',[_c('v-card',{staticClass:"w-100 rounded-lg pa-6",attrs:{"disabled":_vm.loading}},[_c('v-card-title',{staticClass:"montserrat-font"},[(_vm.showAllCourses || !_vm.landingPage)?_c('h1',{class:_vm.landingPage ? 'text-h5' : 'text-subtitle-2'},[_vm._v(" All Tests Series : ")]):_c('h1',{class:_vm.landingPage ? 'text-h5' : 'text-subtitle-2'},[_vm._v(" Popular Tests Series : ")])]),(!_vm.error)?_c('v-card-text',{staticClass:"mt-5"},[(!_vm.loading && _vm.courses.length > 0)?_c('v-row',{attrs:{"justify":"start"}},_vm._l((_vm.topCourses),function(course,i){return _c('v-col',{key:i,attrs:{"md":"2"}},[_c('router-link',{attrs:{"to":{
                  path: ("/course/" + (_vm.allCoursesIndex(course.courseName))),
                }}},[_c('div',{staticClass:"d-flex justify-center align-center flex-column",attrs:{"id":"box"}},[_c('v-avatar',{staticClass:"ma-3",attrs:{"size":"60"}},[_c('v-img',{attrs:{"src":course.logoImg}})],1),_c('div',{staticClass:"text-center grey--text text--darken-3"},[_c('u',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(course.courseName)+" ")])])],1)])],1)}),1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"deep-purple"}})],1)],1):_vm._e(),(
            !_vm.error &&
            _vm.courses.length > 0 &&
            _vm.otherCourses.length > 0 &&
            !_vm.loading
          )?_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAllCourses),expression:"showAllCourses"}]},[_c('v-divider',{staticClass:"mt-5"}),_c('v-card-text',[(!_vm.loading)?_c('v-row',{attrs:{"justify":"start"}},_vm._l((_vm.otherCourses),function(course,i){return _c('v-col',{key:i,attrs:{"md":"2"}},[_c('router-link',{attrs:{"to":{
                      path: ("/course/" + (_vm.allCoursesIndex(course.courseName))),
                    }}},[_c('div',{staticClass:"d-flex justify-center align-center flex-column",attrs:{"id":"box"}},[_c('v-avatar',{staticClass:"ma-3",attrs:{"size":"60"}},[_c('v-img',{attrs:{"src":course.logoImg}})],1),_c('div',{staticClass:"text-center grey--text text--darken-3"},[_c('u',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(" "+_vm._s(course.courseName)+" ")])])],1)])],1)}),1):_vm._e()],1)],1)]):_vm._e(),(!_vm.error && _vm.otherCourses.length > 0 && !_vm.loading)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showAllCourses = !_vm.showAllCourses}}},[_c('v-icon',[_vm._v(_vm._s(_vm.showAllCourses ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1):_vm._e(),(_vm.error && !_vm.loading)?_c('v-card-text',{staticClass:"mt-5"},[_c('v-alert',{attrs:{"dense":"","text":"","type":"warning"}},[_vm._v(" "+_vm._s(_vm.error)+" ")])],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }