import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import * as Cookies from "js-cookie";

import {
  firestore_fieldvalue,
  fire_auth,
  fire_auth_provider,
  //   fire_functions,
  fire_store,
  fire_storage,
} from "@/firebase/init";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      getItem: (key) => Cookies.get(key),
      setItem: (key, value) =>
        Cookies.set(key, value, { expires: 3, secure: true }),
      removeItem: (key) => Cookies.remove(key),
    }),
  ],

  state: {
    auth: null,
    userData: {},
    metaDoc: {
      courses: [],
    },
    pdf_doc: {
      pdfGroups: [],
    },
    questionFolders: {},
    questionCount: {},
  },

  getters: {
    auth: (state) => {
      return state.auth;
    },
    userData: (state) => {
      return state.userData;
    },
    courses: (state) => {
      return state.metaDoc.courses;
    },
    pdfGroups: (state) => {
      return state.pdf_doc.pdfGroups;
    },
    questionFolders: (state) => {
      return state.questionFolders;
    },
    questionCount: (state) => {
      return state.questionCount;
    },
  },

  mutations: {
    setMetaDoc: (state, payload) => {
      state.metaDoc = payload;
    },

    setPdf_doc: (state, payload) => {
      state.pdf_doc = payload;
    },

    // Auth Mutations
    setAuth: (state, payload) => {
      state.auth = payload;
    },
    setUserData: (state, payload) => {
      state.userData = payload;
    },
    logout: (state) => {
      state.auth = null;
      state.userData = {};
      state.metaDoc = {
        courses: [],
      };
      state.pdf_doc = {
        pdfGroups: [],
      };
      state.questionFolders = {};
      state.questionCount = {};
      sessionStorage.clear();
    },

    // home/manage Mutations
    setBatches: (state, payload) => {
      state.batches = payload;
    },
    setQuestionFolders: (state, payload) => {
      state.questionFolders = payload;
    },
    setCourses(state, payload) {
      Vue.set(state.metaDoc, "courses", payload);
    },
    // home/create Mutations
    setQuestionCount: (state, payload) => {
      state.questionCount = payload;
    },
  },

  actions: {
    // Get all courses and their logo links
    getMetaDoc: (context) => {
      const ref = fire_store.collection("meta").doc("meta_doc");

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((res) => {
            const data = res.data();
            context.commit("setMetaDoc", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //submit landing page contact form
    submitContactForm: (context, payload) => {
      const ref = fire_store.collection("contact_form").doc();
      return new Promise((resolve, reject) => {
        ref
          .set(payload)
          .then(() => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // Auth Actions
    login: (context, payload) => {
      return new Promise((resolve, reject) => {
        fire_auth
          .signInWithEmailAndPassword(payload.email, payload.password)
          .then((cred) => {
            //if login successful
            if (cred.user) {
              //get admin uid;
              fire_store
                .collection("meta")
                .doc("admin")
                .get()
                .then(function(doc) {
                  const adminData = doc.data();
                  // if user.uid == admin uid
                  if (cred.user.uid == adminData.adminUID) {
                    //login
                    context.commit("setAuth", cred.user);
                    context
                      .dispatch("getUserData")
                      .then(() => {
                        resolve();
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  } else {
                    reject("Invalid Email or Password. Error Code: ERROR_11");
                  }
                })
                .catch((error) => {
                  console.log(error);
                  reject(
                    "Network Error. Please refresh and try again.  Error Code: ERROR_12"
                  );
                });
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    signInWithGoogle: (context) => {
      var provider = new fire_auth_provider.GoogleAuthProvider();
      return new Promise((resolve, reject) => {
        fire_auth
          .signInWithPopup(provider)
          .then((result) => {
            //successful login
            if (result.user) {
              //get admin uid;
              fire_store
                .collection("meta")
                .doc("admin")
                .get()
                .then(function(doc) {
                  const adminData = doc.data();
                  // if user.uid == admin uid
                  if (result.user.uid == adminData.adminUID) {
                    //login
                    context.commit("setAuth", result.user);
                    context
                      .dispatch("getUserData")
                      .then(() => {
                        resolve();
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  } else {
                    console.log(
                      "Invalid Email or Password. Error Code: ERROR_13"
                    );
                    reject("Invalid Email or Password. Error Code: ERROR_13");
                  }
                })
                .catch((error) => {
                  console.log(error);
                  reject(
                    "Network Error. Please refresh and try again.  Error Code: ERROR_14"
                  );
                });
            } else {
              console.log("Invalid Email or Password. Error Code: ERROR_14");
              reject("Invalid Email or Password. Error Code: ERROR_14");
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getUserData: (context) => {
      const ref = fire_store.collection("users").doc(context.state.auth.uid);

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((res) => {
            const data = res.data();
            context.commit("setUserData", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    logout: (context) => {
      return new Promise((resolve, reject) => {
        fire_auth
          .signOut()
          .then(() => {
            context.commit("logout");
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // home/create Actions

    //increments the folder questionCount straight-away
    submitQuestion: (context, payload) => {
      var batch = fire_store.batch();

      const ref = fire_store.collection("questionsv2").doc(payload.id);

      batch.set(ref, payload);

      const counterRef = fire_store.collection("meta").doc("questionCounter");

      batch.set(
        counterRef,
        {
          [payload.folder]: firestore_fieldvalue.increment(1),
        },
        { merge: true }
      );

      return new Promise((resolve, reject) => {
        batch
          .commit()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },

    getQuestion: (context, questionID) => {
      const ref = fire_store.collection("questionsv2").doc(`${questionID}`);

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((res) => {
            const data = res.data();
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //just updates the questionDoc (does not update question count - update manually)
    updateQuestion: (context, payload) => {
      const ref = fire_store.collection("questionsv2").doc(payload.id);

      return new Promise((resolve, reject) => {
        ref
          .set(payload)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    decrementFolderCount: (context, payload) => {
      const counterRef = fire_store.collection("meta").doc("questionCounter");

      return new Promise((resolve, reject) => {
        counterRef
          .update({
            [payload]: firestore_fieldvalue.increment(-1),
          })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },

    getNumberOfQuestions: (context) => {
      const ref = fire_store.collection("meta").doc("questionCounter");

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((doc) => {
            const data = doc.data();
            context.commit("setQuestionCount", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    //increments the course and testGroup count straight-away
    submitTest: (context, payload) => {
      var batch = fire_store.batch();

      const ref = fire_store.collection("testsv2").doc(payload.id);

      batch.set(ref, payload);

      const testsCounterRef = fire_store.collection("meta").doc("testsCounter");
      const testGroupCounterRef = fire_store
        .collection("meta")
        .doc("testGroupCounter");

      var testsCounterPayload = {};
      var testGroupCounterPayload = {};

      //selectedTestGroup object with {course(key) : testGroup(value)} schema
      Object.keys(payload.selectedTestGroup).forEach((course) => {
        testsCounterPayload[course] = firestore_fieldvalue.increment(1);

        //courseName.testGroupName
        testGroupCounterPayload[course] = {};
        testGroupCounterPayload[course][
          payload.selectedTestGroup[course]
        ] = firestore_fieldvalue.increment(1);
      });

      batch.set(testsCounterRef, testsCounterPayload, { merge: true });

      batch.set(testGroupCounterRef, testGroupCounterPayload, { merge: true });

      return new Promise((resolve, reject) => {
        batch
          .commit()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },

    updateTest: (context, payload) => {
      const ref = fire_store.collection("testsv2").doc(payload.id);

      return new Promise((resolve, reject) => {
        ref
          .set(payload)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },

    testUpdateIncrement: (context, payload) => {
      var batch = fire_store.batch();

      const testsCounterRef = fire_store.collection("meta").doc("testsCounter");

      const testGroupCounterRef = fire_store
        .collection("meta")
        .doc("testGroupCounter");

      var testsCounterPayload = {};
      var testGroupCounterPayload = {};

      //increment courses loop
      payload.increment.courses.forEach((course) => {
        testsCounterPayload[course] = firestore_fieldvalue.increment(1);
      });

      //increment testGroups loop
      Object.keys(payload.increment.testGroups).forEach((course) => {
        //courseName.testGroupName
        testGroupCounterPayload[course] = {};
        testGroupCounterPayload[course][
          payload.increment.testGroups[course]
        ] = firestore_fieldvalue.increment(1);
      });

      //decrement courses loop
      payload.decrement.courses.forEach((course) => {
        testsCounterPayload[course] = firestore_fieldvalue.increment(-1);
      });

      //decrement testGroups loop
      Object.keys(payload.decrement.testGroups).forEach((course) => {
        //courseName.testGroupName
        testGroupCounterPayload[course] = {};
        testGroupCounterPayload[course][
          payload.decrement.testGroups[course]
        ] = firestore_fieldvalue.increment(-1);
      });

      batch.set(testsCounterRef, testsCounterPayload, { merge: true });
      batch.set(testGroupCounterRef, testGroupCounterPayload, { merge: true });

      return new Promise((resolve, reject) => {
        batch
          .commit()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },

    // home/manage Actions

    //also gets number of questions in folders

    saveQuestionFolders: (context, payload) => {
      const ref = fire_store.collection("meta").doc("questionFolders");

      return new Promise((resolve, reject) => {
        ref
          .set(payload)
          .then(() => {
            context.commit("setQuestionFolders", payload);
            context.dispatch("getNumberOfQuestions");
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    getQuestionFolders: (context) => {
      const ref = fire_store.collection("meta").doc("questionFolders");

      // also fetch questionCount as it is mostly needed with this function
      context.dispatch("getNumberOfQuestions");

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((doc) => {
            if (doc.exists) {
              const data = doc.data();
              context.commit("setQuestionFolders", data);
              resolve(data);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllQuestions: (context, payload) => {
      var ref = "";

      switch (payload.requestType) {
        case "prev":
          ref = fire_store
            .collection("questionsv2")
            .orderBy("id", "desc")
            .endBefore(payload.doc)
            .limitToLast(10);
          break;
        case "next":
          ref = fire_store
            .collection("questionsv2")
            .orderBy("id", "desc")
            .startAfter(payload.doc)
            .limit(10);

          break;
        default:
          ref = fire_store
            .collection("questionsv2")
            .orderBy("id", "desc")
            .limit(10);
      }

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const resData = snapshot.docs.map((doc) => doc.data());
            const res = {
              data: resData,
              firstAndLastVisible: {
                firstVisible: snapshot.docs[0],
                lastVisible: snapshot.docs[snapshot.docs.length - 1],
              },
            };
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getSelectedQuestions: (context, payload) => {
      var promises = [];

      payload.forEach((id) =>
        promises.push(
          fire_store
            .collection("questionsv2")
            .doc(id)
            .get()
        )
      );

      return new Promise((resolve, reject) => {
        var allQuestions = [];
        return Promise.all(promises)
          .then((resArr) => {
            resArr.forEach((res) => {
              //is a document
              var docData = res.data();
              //questionsLogObj[docData.id] is docSection;
              allQuestions.push(docData);
            });

            resolve(allQuestions);
          })
          .catch((error) => reject(error));
      });
    },

    getFolderQuestions: (context, payload) => {
      var ref = "";

      switch (payload.requestType) {
        case "prev":
          ref = fire_store
            .collection("questionsv2")
            .where("folder", "==", payload.folder)
            .orderBy("id", "desc")
            .endBefore(payload.doc)
            .limitToLast(10);
          break;
        case "next":
          ref = fire_store
            .collection("questionsv2")
            .where("folder", "==", payload.folder)
            .orderBy("id", "desc")
            .startAfter(payload.doc)
            .limit(10);
          break;
        default:
          ref = fire_store
            .collection("questionsv2")
            .where("folder", "==", payload.folder)
            .orderBy("id", "desc")
            .limit(10);
      }

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const resData = snapshot.docs.map((doc) => doc.data());
            const res = {
              data: resData,
              firstAndLastVisible: {
                firstVisible: snapshot.docs[0],
                lastVisible: snapshot.docs[snapshot.docs.length - 1],
              },
            };
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCourseQuestions: (context, payload) => {
      var ref = "";

      switch (payload.requestType) {
        case "prev":
          ref = fire_store
            .collection("questionsv2")
            .where("course", "==", payload.course)
            .orderBy("id", "desc")
            .endBefore(payload.doc)
            .limitToLast(10);
          break;
        case "next":
          ref = fire_store
            .collection("questionsv2")
            .where("course", "==", payload.course)
            .orderBy("id", "desc")
            .startAfter(payload.doc)
            .limit(10);
          break;
        default:
          ref = fire_store
            .collection("questionsv2")
            .where("course", "==", payload.course)
            .orderBy("id", "desc")
            .limit(10);
      }

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const resData = snapshot.docs.map((doc) => doc.data());
            const res = {
              data: resData,
              firstAndLastVisible: {
                firstVisible: snapshot.docs[0],
                lastVisible: snapshot.docs[snapshot.docs.length - 1],
              },
            };
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteQuestion: (context, payload) => {
      var batch = fire_store.batch();

      const ref = fire_store.collection("questionsv2").doc(payload.id);

      batch.delete(ref);

      const counterRef = fire_store.collection("meta").doc("questionCounter");

      batch.set(
        counterRef,
        {
          [payload.folder]: firestore_fieldvalue.increment(-1),
        },
        { merge: true }
      );

      return new Promise((resolve, reject) => {
        batch
          .commit()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },

    getAllTests: (context, payload) => {
      var ref = "";

      switch (payload.requestType) {
        case "prev":
          ref = fire_store
            .collection("testsv2")
            .orderBy("id", "desc")
            .endBefore(payload.doc)
            .limitToLast(10);
          break;
        case "next":
          ref = fire_store
            .collection("testsv2")
            .orderBy("id", "desc")
            .startAfter(payload.doc)
            .limit(10);

          break;
        default:
          ref = fire_store
            .collection("testsv2")
            .orderBy("id", "desc")
            .limit(10);
      }

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const resData = snapshot.docs.map((doc) => doc.data());
            const res = {
              data: resData,
              firstAndLastVisible: {
                firstVisible: snapshot.docs[0],
                lastVisible: snapshot.docs[snapshot.docs.length - 1],
              },
            };
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteTest: (context, payload) => {
      var batch = fire_store.batch();

      const ref = fire_store.collection("testsv2").doc(payload.id);

      batch.delete(ref);

      const testsCounterRef = fire_store.collection("meta").doc("testsCounter");
      const testGroupCounterRef = fire_store
        .collection("meta")
        .doc("testGroupCounter");

      var testsCounterPayload = {};
      var testGroupCounterPayload = {};

      //selectedTestGroup object with {course(key) : testGroup(value)} schema
      Object.keys(payload.selectedTestGroup).forEach((course) => {
        testsCounterPayload[course] = firestore_fieldvalue.increment(-1);

        //courseName.testGroupName
        testGroupCounterPayload[course] = {};
        testGroupCounterPayload[course][
          payload.selectedTestGroup[course]
        ] = firestore_fieldvalue.increment(-1);
      });

      batch.set(testsCounterRef, testsCounterPayload, { merge: true });

      batch.set(testGroupCounterRef, testGroupCounterPayload, { merge: true });

      return new Promise((resolve, reject) => {
        batch
          .commit()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },

    saveCourses: (context, payload) => {
      const ref = fire_store.collection("meta").doc("meta_doc");

      return new Promise((resolve, reject) => {
        ref
          .set({ courses: payload }, { merge: true })
          .then(() => {
            context.commit("setCourses", payload);
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    addCourse: (context, payload) => {
      var courses = payload.courses;

      //course_logos/courseName.jpeg
      const storageRef = fire_storage
        .ref()
        .child(`course_logos/${courses[courses.length - 1].courseName}.jpeg`);

      return new Promise((resolve, reject) => {
        storageRef
          .put(payload.logoImg)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
              courses[courses.length - 1].logoImg = url;
              context
                .dispatch("saveCourses", courses)
                .then(() => {
                  resolve();
                })
                .catch((err) => {
                  reject(err);
                });
            });
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    getPdfDoc: (context) => {
      const ref = fire_store.collection("meta").doc("pdf_doc");

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((res) => {
            const data = res.data();
            context.commit("setPdf_doc", data);
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    savePdfGroups: (context, payload) => {
      const ref = fire_store.collection("meta").doc("pdf_doc");
      return new Promise((resolve, reject) => {
        ref
          .set({ pdfGroups: payload }, { merge: true })
          .then(() => {
            context.commit("setPdf_doc", { pdfGroups: payload });
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    addPDF: (context, payload) => {
      var pdfObj = payload.pdfObj;

      const ref = fire_store.collection("pdfs").doc(`${pdfObj.id}`);

      //pdfs/pdfObj_id/pdfName.pdf
      const storageRef = fire_storage
        .ref()
        .child(`pdfs/${pdfObj.id}/${pdfObj.pdfName}.pdf`);

      return new Promise((resolve, reject) => {
        storageRef
          .put(payload.pdfFile)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
              pdfObj.pdfURL = url;
              ref
                .set(pdfObj)
                .then(() => {
                  resolve();
                })
                .catch((error) => {
                  reject(error);
                });
            });
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    getpdfs: (context, payload) => {
      var ref = "";

      switch (payload.requestType) {
        case "prev":
          ref = fire_store
            .collection("pdfs")
            .where("selectedFolder", "==", payload.folder)
            .where("selectedGroup", "==", payload.group)
            .orderBy("id", "desc")
            .endBefore(payload.doc)
            .limitToLast(10);
          break;
        case "next":
          ref = fire_store
            .collection("pdfs")
            .where("selectedFolder", "==", payload.folder)
            .where("selectedGroup", "==", payload.group)
            .orderBy("id", "desc")
            .startAfter(payload.doc)
            .limit(10);

          break;
        default:
          ref = fire_store
            .collection("pdfs")
            .where("selectedFolder", "==", payload.folder)
            .where("selectedGroup", "==", payload.group)
            .orderBy("id", "desc")
            .limit(10);
      }

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const resData = snapshot.docs.map((doc) => doc.data());
            const res = {
              data: resData,
              firstAndLastVisible: {
                firstVisible: snapshot.docs[0],
                lastVisible: snapshot.docs[snapshot.docs.length - 1],
              },
            };
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deletePDF: (context, payload) => {
      //pdfs/pdfObj_id/pdfName.pdf
      const storageRef = fire_storage
        .ref()
        .child(`pdfs/${payload.id}/${payload.pdfName}.pdf`);

      const ref = fire_store.collection("pdfs").doc(`${payload.id}`);

      return new Promise((resolve, reject) => {
        storageRef
          .delete()
          .then(() => {
            ref
              .delete()
              .then(() => {
                resolve();
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteFolderName: (context, payload) => {
      const ref = fire_store.collection("meta").doc("questionFolders");

      return new Promise((resolve, reject) => {
        ref
          .update({ folderNames: firestore_fieldvalue.arrayRemove(payload) })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    addFolderName: (context, payload) => {
      const ref = fire_store.collection("meta").doc("questionFolders");

      return new Promise((resolve, reject) => {
        ref
          .update({ folderNames: firestore_fieldvalue.arrayUnion(payload) })
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // home/view Actions
    getContactFormEntries: () => {
      var ref = fire_store
        .collection("contact_form")
        .orderBy("datetime", "desc");

      return new Promise((resolve, reject) => {
        let entryObj = {};
        ref
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              let entry = doc.data();
              entry.docId = doc.id;
              entryObj[doc.id] = entry;
            });
            resolve(entryObj);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteContactFormEntry: (context, payload) => {
      const ref = fire_store.collection("contact_form").doc(payload);

      return new Promise((resolve, reject) => {
        ref
          .delete()
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getTestQuestions: async (context, testQuestionsObj) => {
      var responsePayload = {
        folderQuestions: {}, // {folderName:[]}
        questionsDataObj: {},
      };

      var error = null;

      var folderNamesArr = [];
      var questionsIDArr = [];

      // Populate above Arrays for firestore call
      Object.keys(testQuestionsObj).forEach((key) => {
        //if object it is an array : containing IDs of questions
        if (testQuestionsObj[key].constructor === Array) {
          //add it to folderNamesArr
          questionsIDArr = questionsIDArr.concat(testQuestionsObj[key]);
        } else {
          // else it is an obj with folder {folderName}
          folderNamesArr.push(testQuestionsObj[key].folderName);
        }
      });

      // if isObj only get questions (recieved ids and folderNames through testQuestions)

      //if not isObj: it is a test id; get both testObj and use it to get questionsData
      // can use destructuring
      responsePayload.folderQuestions = await context
        .dispatch("getTestFolderQuestions", folderNamesArr)
        .catch((e) => (error = e));

      if (!error) {
        responsePayload.questionsDataObj = await context
          .dispatch("getArrayQuestions", questionsIDArr)
          .catch((e) => (error = e));
      }

      //rn responsePayload.folderQuestions contains both allQuestions and folderQuestionIDs arr for each folder
      //move allQuestions from folderQuestions to questionsDataObj (concat-ish)
      responsePayload.questionsDataObj = {
        ...responsePayload.questionsDataObj,
        ...responsePayload.folderQuestions.allQuestions,
      };

      //responsePayload.folderQuestions = {folderName:[questionID_1, ....]}
      responsePayload.folderQuestions =
        responsePayload.folderQuestions.folderQuestionIDs;

      return new Promise((resolve, reject) => {
        if (error) {
          reject(error);
        } else {
          resolve(responsePayload);
        }
      });
    },

    // returns {allQuestions, folderQuestionIDs} where allQuestions is {questionID: questionData}
    // folderQuestionIDs is {folderName:Arr of IDs}
    getTestFolderQuestions: (context, folderNamesArr) => {
      var promises = [];

      folderNamesArr.forEach((folder) =>
        promises.push(
          fire_store
            .collection("questionsv2")
            .where("folder", "==", folder)
            .get()
        )
      );

      return new Promise((resolve, reject) => {
        var allQuestions = {};
        var folderQuestionIDs = {};

        return Promise.all(promises)
          .then((resArr) => {
            // resArr is array of objects for each folder respective to folderNamesArr

            resArr.forEach((folderRes, folderIndex) => {
              // folderQuestionIDs.folderName = [questionID_1, .....]
              folderQuestionIDs[folderNamesArr[folderIndex]] = [];

              if (!folderRes.empty) {
                folderRes.docs.forEach((doc) => {
                  //is a document
                  var docData = doc.data();

                  allQuestions[docData.id] = docData;

                  folderQuestionIDs[folderNamesArr[folderIndex]].push(
                    docData.id
                  );
                });
              }
            });

            resolve({ allQuestions, folderQuestionIDs });
          })
          .catch((error) => reject(error));
      });
    },

    getArrayQuestions: (context, questionsIDArr) => {
      var promises = [];

      questionsIDArr.forEach((id) =>
        promises.push(
          fire_store
            .collection("questionsv2")
            .doc(id)
            .get()
        )
      );

      return new Promise((resolve, reject) => {
        var allQuestions = {};

        return Promise.all(promises)
          .then((resArr) => {
            resArr.forEach((res) => {
              //is a document
              var docData = res.data();
              allQuestions[docData.id] = docData;
            });

            resolve(allQuestions);
          })
          .catch((error) => reject(error));
      });
    },

    getTestScoresDoc: (context, testID) => {
      const ref = fire_store.collection("resultsv2").doc(testID);

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((res) => {
            const data = res.data();
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllResultsByScore: (context, payload) => {
      var ref = "";

      switch (payload.requestType) {
        case "prev":
          ref = fire_store
            .collection("resultsv2")
            .doc(payload.testID)
            .collection("users")
            .orderBy("score", "desc")
            .endBefore(payload.doc)
            .limitToLast(10);
          break;
        case "next":
          ref = fire_store
            .collection("resultsv2")
            .doc(payload.testID)
            .collection("users")
            .orderBy("score", "desc")
            .startAfter(payload.doc)
            .limit(10);
          break;
        default:
          ref = fire_store
            .collection("resultsv2")
            .doc(payload.testID)
            .collection("users")
            .orderBy("score", "desc")
            .limit(10);
      }

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((snapshot) => {
            const resData = snapshot.docs.map((doc) => doc.data());
            const res = {
              data: resData,
              firstAndLastVisible: {
                firstVisible: snapshot.docs[0],
                lastVisible: snapshot.docs[snapshot.docs.length - 1],
              },
            };
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCodeSales: () => {
      const ref = fire_store.collection("meta").doc("discount_codes");

      return new Promise((resolve, reject) => {
        ref
          .get()
          .then((res) => {
            const data = res.data();
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
});
