import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import store from "./store";

import Landing from "./views/Landing.vue";

Vue.use(VueRouter);
Vue.use(VueMeta);

// for (auth-enabling) routes that should redirect if already authenticated
function toHomeIfAlreadyAuth(to, from, next) {
  const auth = store.getters.auth;
  if (auth) {
    next("/home");
  } else {
    next();
  }
}

// for routes that require authentication
function toLoginIfNotAuth(to, from, next) {
  const auth = store.getters.auth;
  if (!auth) {
    next({
      path: "/login",
      query: { nextUrl: to.fullPath },
    });
  } else {
    next();
  }
}

const routes = [
  // Landing Page
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },

  // Auth Routes
  {
    path: "/login",
    name: "Login",
    component: () => import("./views/auth/Login.vue"),
    beforeEnter: (to, from, next) => {
      toHomeIfAlreadyAuth(to, from, next);
    },
  },

  // User Routes
  {
    path: "/home",
    name: "Home",
    component: () => import("./views/home/Home.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },

  //Misc
  {
    path: "/terms-and-conditions",
    name: "TnC",
    component: () => import("./views/misc/TnC.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("./views/misc/PrivacyPolicy.vue"),
  },

  // create routes
  {
    path: "/create/question",
    name: "createQuestion",
    props: true,
    component: () => import("./views/home/create/Question.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },
  {
    path: "/create/question/:id",
    name: "editQuestion",
    props: true,
    component: () => import("./views/home/create/Question.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },
  {
    path: "/create/test",
    name: "createTest",
    props: true,
    component: () => import("./views/home/create/Test.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },
  {
    path: "/create/pdf",
    name: "createPdf",
    props: true,
    component: () => import("./views/home/create/Pdf.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },

  // manage routes
  {
    path: "/manage/question-bank",
    name: "questionBank",
    component: () => import("./views/home/manage/QuestionBank.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },
  {
    path: "/manage/courses",
    name: "courses",
    component: () => import("./views/home/manage/Courses.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },
  {
    path: "/manage/pdfs",
    name: "Pdfs",
    component: () => import("./views/home/manage/Pdfs/Pdfs.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },
  {
    path: "/manage/pdfs/:id",
    name: "ManagePdfGroup",
    component: () => import("./views/home/manage/Pdfs/ManagePdfGroup.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },
  {
    path: "/manage/edit-tests",
    name: "editTests",
    component: () => import("./views/home/manage/EditTests.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },
  // view routes
  {
    path: "/view/results",
    name: "viewResults",
    component: () => import("./views/home/view/Results.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },
  {
    path: "/view/result/test",
    name: "viewTestResult",
    props: true,
    component: () => import("./views/home/view/TestResult.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },
  {
    path: "/view/student-result",
    name: "viewStudentResult",
    props: true,
    component: () => import("./views/home/view/StudentResult.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },
  {
    path: "/view/code-sales",
    name: "viewCodeSales",
    component: () => import("./views/home/view/CodeSales.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },
  {
    path: "/view/contact-form",
    name: "viewContactForm",
    component: () => import("./views/home/view/ContactForm.vue"),
    beforeEnter: (to, from, next) => {
      toLoginIfNotAuth(to, from, next);
    },
  },

  // 404
  {
    path: "*",
    name: "NotFound404",
    component: () => import("./views/NotFound404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;
