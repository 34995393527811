<template>
  <!-- TODO: Update Data -->
  <div id="hero_header" class="d-flex justify-center align-center">
    <!-- Overlay Gradient sheet -->
    <v-sheet id="sheet" class="d-flex align-center rounded-lg">
      <v-carousel cycle hide-delimiters show-arrows-on-hover>
        <!-- Slides -->
        <v-carousel-item v-for="(slide, i) in slideData" :key="i">
          <v-row class="fill-height" align="center" justify="center" no-gutters>
            <!-- Empty Filler Column -->
            <v-col sm="12" md="1" class="px-12">
              <v-spacer></v-spacer>
            </v-col>

            <!-- Text Column -->
            <v-col cols="12" sm="12" md="5" class="pa-4 pa-md-12 text-left">
              <!-- Welcome to PlusMinus -->
              <v-chip large color="deep-purple" class="white--text mb-6 px-5">
                Welcome to PlusMinus
              </v-chip>

              <!-- Slide Heading -->
              <h1 class="text-h2 montserrat-font mb-4 white--text">
                {{ slide.heading }}
              </h1>

              <!-- Slide Text -->
              <h3
                class="text-subtitle-1 montserrat-font pa-1 pa-md-2 white--text"
              >
                {{ slide.text }}
              </h3>

              <!-- Registration Buttons -->
              <div>
                <v-btn class="my-5 mx-2" to="/login">Log In</v-btn>
                <v-btn class="my-5 mx-2" to="/login">Register</v-btn>
              </div>
            </v-col>

            <v-col sm="12" md="5" class="px-12">
              <v-img
                class="mx-auto hidden-sm-and-down"
                style="max-height: 50vh"
                :src="require(`../../assets/${slide.image}`)"
                contain
              ></v-img>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "HeroHeader",
  data: () => ({
    slideData: [
      {
        heading: "Prepare in your own schedule.",
        text: "Plusminus is a trusted government job exam preparation website. Providing everything you need to crack that exam.",
        image: "landing/undraw_online_collaboration_7pfp.svg",
      },
      {
        heading: "Manage your institution.",
        text: "Manage your institution batches and allow students to join your institution.",
        image: "landing/undraw_teacher_35j2.svg",
      },
      {
        heading: "Conduct tests, online",
        text: "You can participate in tests online, with an easy to learn and intuitive interface.",
        image: "landing/undraw_online_test_gba7.svg",
      },
      {
        heading: "Instant result analysis",
        text: "See the detailed performance analysis, and solutions provided by the institution instantly.",
        image: "landing/undraw_instat_analysis_ajld.svg",
      },
    ],
  }),
};
</script>

<style>
#hero_header {
  min-height: 93.5vh;
  width: 100vw;
}

#hero_header > #sheet {
  min-height: 88vh;
  width: 90vw;

  background: #fc5c7d; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #6a82fb,
    #fc5c7d
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #6a82fb,
    #fc5c7d
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>